/* IMPORTS */

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sofia+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");

/* UNIVERSAL */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  color: #1ebaa3;
  transition: 0.3s ease-in-out;
}

html {
  background-color: #1c1917;
  scroll-behavior: smooth;
}

/* FORM */

.form-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem;
  font-family: "Sofia Sans", sans-serif;
}

.form-heading-wrapper {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
}

.form-heading-wrapper-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.form-heading-wrapper-2 div {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}

.form-heading-container {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.form-heading-container h1 {
  font-size: 3rem;
}

.form {
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.form-topic-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 3rem;
}

.form-topic-wrapper h2 {
  margin-bottom: 1rem;
}

.user-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.user-box span,
h2 span {
  color: red;
}

.user-box input {
  width: 66%;
  background-color: transparent;
  border: none;
  border-bottom: 0.5px solid #fff;
  transition: 0.4s ease-in-out;
  color: #fff;
}

.user-box input:active,
.user-box input:focus {
  outline: none;
  border-bottom: 0.5px solid #1ebaa3;
}

.user-box textarea {
  width: 100%;
  background-color: transparent;
  outline: none;
  border-radius: 25px;
  padding: 0.65rem;
  height: 5rem;
  resize: none;
  color: #fff;
}

.user-box textarea::-webkit-scrollbar {
  display: none;
}

/* NUMBER INPUT FIX */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* FIREFOX NUMBER INPUT FIX */
input[type="number"] {
  -moz-appearance: textfield;
}

.button-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

button {
  padding: 10px 20px;
  background-color: #000;
  border: none;
  border-radius: 5px;
  color: #1ebaa3;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  letter-spacing: 1px;
}

button:hover {
  background: #1ebaa3;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #1ebaa3, 0 0 25px #1ebaa3, 0 0 50px #1ebaa3,
    0 0 100px #1ebaa3;
}

@media screen and (max-width: 1024px) {
  .form {
    width: 100vw;
  }
  .form-heading-wrapper {
    flex-direction: column;
  }
  .form-heading-wrapper-2 div {
    padding-top: 2rem;
  }
  .form-topic-wrapper {
    padding-bottom: 1rem;
    align-items: center;
  }
  .form-topic-wrapper h2 {
    text-align: center;
  }
}

/* MODAL */

.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 5000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(40, 40, 40, 0.65);
  backdrop-filter: blur(5px);
}

.modal-box {
  background-color: #000;
  padding: 0;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 3rem; */
  margin-top: auto;
  margin-bottom: auto;
}

.modal-img-text {
  padding: 5rem 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.cross-btn {
  background-color: #1c1917;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  /* position: absolute;
  z-index: 999; */
}

.cross-btn:hover {
  cursor: pointer;
}

.modal-box img {
  filter: invert();
}

.modal-text {
  font-size: 1.5rem;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .modal-box {
    padding: 4rem 3.5rem;
  }
}

/* NAVBAR */

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background: #000000e7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}

header a {
  text-decoration: none;
}

nav a {
  margin: 0 1rem;
  text-decoration: none;
}
nav {
  display: flex;
  justify-content: flex-end;
}

header .nav-btn {
  /* padding: 5px; */
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #079c74;
  visibility: collapse;
  opacity: 0;
  font-size: 1.8rem;
}

.nav-close-btn {
  display: none;
}

header div,
nav {
  display: flex;
  width: fit-content;
  align-items: center;
}
.btn-discord {
  margin-left: auto;
  background: #079c74;
  border-radius: 10px;
  text-decoration: none;
  padding: 5px;
  margin-left: 0%;
  color: white;
  transition: 0.5s ease-in-out;
}
.btn-nav {
  visibility: collapse;
}
.btn-discord:hover {
  background: #05533e;
  transition: 0.5s ease-in-out;
  color: #bcc0ff;
}

.outNav {
  display: none;
}

@media (max-width: 768px) {
  .outNav {
    display: inline-block;
  }
  header {
    justify-content: space-between;
  }
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    padding-bottom: 10px;
    top: 0;
    left: 0;
    z-index: 1021;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 1.5rem;
    background-color: #00000090;
    backdrop-filter: blur(20px);
    transition: 1s;
    transform: translateY(-100vh);
  }

  header .responsive_nav {
    transform: none;
    justify-content: end;
    align-items: flex-end;
  }
  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  nav .nav-close-btn {
    display: inline-block;
    text-align: end;
    padding-right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
  nav .btn-discord {
    background: #079c74;
    border-radius: 10px;
    color: white;
    padding: 5px;
    margin-left: 0;
  }
  .btn-out {
    visibility: collapse;
  }
  .btn-nav {
    visibility: visible;
  }
}

/*css for the logo on the navbar*/
.Logo-navbar {
  /* width: 48px; */
  height: 50px;
  padding-left: 10px;
  align-items: center;
  margin-left: 15px;
}
/* responsiveness for logo of sellular */
.nav-logo {
  height: 50px;
  display: flex;
  align-items: center;
}
.nav-link {
  text-decoration: none;
  display: inline;
}
.list-1 {
  text-align: end;
  margin-right: 10px;
}
.nav-item {
  display: inline-block;
  padding-top: 10px;
  padding-left: 30px;
  padding-bottom: 10px;
}

.logoText {
  display: flex;
  align-items: center;
}

.logo-text {
  color: #1ebaa3;
  /* font-size: 2rem; */
  padding-left: 10px;
  text-decoration: 0;
  font-weight: 700;
  margin-bottom: 0;
}

.logo-box {
  text-align: center;
}
@media (max-width: 993px) {
  .button-join {
    margin-left: 0px;
  }
}

.navbar-toggler {
  border: 0px;
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}
.toggler-icon {
  width: 30px;
  height: 3px;
  background-color: #ffffff;
  display: block;
  transition: all 0.2s;
}
.navbar-toggler.collapsed {
  border: 0;
  padding-right: 20px;
}

.middle-bar {
  margin: 5px auto;
}
.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}
.navbar-toggler .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}
.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 10%;
}
.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
}
.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}
.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
}
.navbar-toggler.collapsed .toggler-icon {
  background-color: #13caa2;
}

.collapse {
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 26%;
}
.button-join {
  background-color: #187058;
  padding: 10px 20px;
  border-radius: 10px;
  text-decoration: none;
  color: rgb(255, 255, 255);
  transition-duration: 0.4s;
  /* justify-content:baseline; */
}
@media (max-width: 998px) {
  .button-join {
    width: 25vw;
  }
}
.fa-brands {
  padding-right: 10px;
  position: relative;
}
.list-2 {
  justify-content: end;
  text-align: end;
}
/* Nav-Link Hover effect */
.nav-link {
  padding: 0.5rem 0;
  text-decoration: none;
  display: inline-block;
  position: relative;
  color: #ffffff;
  font-size: 19px;
  box-sizing: border-box;
}
.nav-link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0fc5b0;
  transform-origin: bottom right;
  transition: transform 0.25s ease-in-out;
}
.nav-link:hover {
  color: #fff;
}
.nav-link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.button-join:hover {
  cursor: pointer;
  background-color: #54579e;
  color: white;
  box-shadow: 0 5px 15px rgba(84, 87, 158, 0.5);
}
.button-join::before {
  height: 150%;
  width: 7px;
  content: "";
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  position: absolute;
  left: -25%;
  bottom: -25%;
  transform: rotate(40deg);
}
.button-join:hover::before {
  bottom: -25%;
  animation: slide 0.45s ease 1;
}
@keyframes AnimationName {
  0% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(-12deg, -12deg);
  }
  50% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(6deg, 6deg);
  }
  100% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(-4deg, -4deg);
  }
}
.img-dis {
  position: relative;
  top: -1.3px;
}

/* @media (max-width: 500px) {
  .navbar-container input[type="checkbox"]:checked ~ .logo {
    display: none;
  }
} */

/* HERO */

.hero-wrapper {
  position: relative;
  /* top: 90px; */
}

.hero {
  display: inline-block;
  width: 100%;
  height: 100vh;
  position: relative;
}

.diagonal-hero-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1c1917;
  background: -webkit-linear-gradient(to right, #000, #1c1917);
  background: linear-gradient(to right, #000, #1c1917);
  z-index: -1;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.stars {
  z-index: -1;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: opacity 1s ease-in-out;
}

.stars > .small {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1434px 1998px #767676, 1855px 1916px #767676,
    1527px 1420px #767676, 887px 471px #767676, 1482px 127px #767676,
    1369px 365px #767676, 264px 342px #767676, 38px 967px #767676,
    723px 340px #767676, 1990px 260px #767676, 1443px 131px #767676,
    729px 1119px #767676, 302px 1290px #767676, 1596px 76px #767676,
    432px 468px #767676, 154px 860px #767676, 531px 438px #767676,
    1843px 1699px #767676, 546px 964px #767676, 1161px 999px #767676,
    483px 1529px #767676, 730px 771px #767676, 1604px 725px #767676,
    1012px 8px #767676, 1608px 276px #767676, 963px 682px #767676,
    931px 1075px #767676, 713px 448px #767676, 973px 306px #767676,
    389px 193px #767676, 1278px 177px #767676, 1092px 836px #767676,
    876px 547px #767676, 134px 1276px #767676, 341px 1123px #767676,
    625px 1744px #767676, 688px 1927px #767676, 1231px 959px #767676,
    1107px 147px #767676, 1725px 1445px #767676, 1079px 222px #767676,
    1171px 486px #767676, 1719px 1754px #767676, 183px 1367px #767676,
    1444px 177px #767676, 292px 933px #767676, 1450px 133px #767676,
    463px 135px #767676, 884px 300px #767676, 1292px 628px #767676,
    1789px 133px #767676, 1320px 1913px #767676, 283px 1462px #767676,
    1220px 1847px #767676, 1788px 260px #767676, 1108px 1349px #767676,
    355px 26px #767676, 1214px 380px #767676, 1189px 345px #767676,
    1403px 1908px #767676, 61px 1951px #767676, 250px 1495px #767676,
    1808px 944px #767676, 918px 852px #767676, 1242px 266px #767676,
    1376px 288px #767676, 352px 1900px #767676, 382px 1897px #767676,
    332px 444px #767676, 1597px 379px #767676, 331px 737px #767676,
    1047px 1254px #767676, 512px 237px #767676, 91px 1450px #767676,
    1623px 171px #767676, 597px 1337px #767676, 652px 1430px #767676,
    460px 238px #767676, 482px 1950px #767676, 1508px 1188px #767676,
    1781px 980px #767676, 659px 718px #767676, 276px 1231px #767676,
    1138px 1585px #767676, 1765px 429px #767676, 121px 1117px #767676,
    1742px 702px #767676, 922px 982px #767676, 1995px 1424px #767676,
    250px 1272px #767676, 841px 965px #767676, 197px 1036px #767676,
    374px 1596px #767676, 913px 1800px #767676, 1191px 1803px #767676,
    1033px 876px #767676, 1811px 1054px #767676, 651px 441px #767676,
    1155px 1765px #767676, 23px 483px #767676, 1892px 975px #767676,
    30px 1248px #767676, 1316px 220px #767676, 1130px 1919px #767676,
    1566px 762px #767676, 533px 742px #767676, 1958px 1511px #767676,
    1185px 3px #767676, 314px 1114px #767676, 108px 568px #767676,
    411px 1751px #767676, 1877px 1160px #767676, 538px 1583px #767676,
    1362px 377px #767676, 500px 428px #767676, 1091px 1167px #767676,
    1678px 1117px #767676, 757px 1934px #767676, 669px 458px #767676,
    1456px 1710px #767676, 1292px 471px #767676, 1755px 1490px #767676,
    495px 1118px #767676, 494px 586px #767676, 717px 1425px #767676,
    624px 1144px #767676, 486px 901px #767676, 1562px 1112px #767676,
    1282px 719px #767676, 48px 1984px #767676, 326px 1061px #767676,
    1780px 109px #767676, 1171px 1242px #767676, 426px 537px #767676,
    118px 1976px #767676, 1214px 1573px #767676, 1862px 539px #767676,
    1045px 635px #767676, 844px 1324px #767676, 937px 439px #767676,
    862px 712px #767676, 1674px 1891px #767676, 1266px 802px #767676,
    1145px 342px #767676, 1781px 1951px #767676, 1458px 139px #767676,
    951px 593px #767676, 1172px 1874px #767676, 1646px 1752px #767676,
    767px 1793px #767676, 1639px 112px #767676, 114px 1179px #767676,
    878px 1322px #767676, 1455px 1121px #767676, 969px 1048px #767676,
    1383px 875px #767676, 721px 1098px #767676, 354px 1838px #767676,
    875px 1481px #767676, 1194px 1765px #767676, 1835px 1969px #767676,
    630px 204px #767676, 1644px 1129px #767676, 1274px 263px #767676,
    1549px 746px #767676, 816px 1686px #767676, 945px 343px #767676,
    385px 1913px #767676, 622px 1059px #767676, 1355px 151px #767676,
    1744px 488px #767676, 1899px 251px #767676, 171px 409px #767676,
    923px 657px #767676, 1769px 1585px #767676, 231px 1675px #767676,
    1169px 931px #767676, 1753px 1239px #767676, 893px 1020px #767676,
    1745px 1891px #767676, 1419px 98px #767676, 428px 1137px #767676,
    1805px 1588px #767676, 1810px 1758px #767676, 1934px 1868px #767676,
    496px 925px #767676, 1572px 125px #767676, 11px 861px #767676,
    1594px 1484px #767676, 927px 1848px #767676, 1276px 485px #767676,
    1897px 1565px #767676, 1971px 109px #767676, 264px 1318px #767676,
    684px 1589px #767676, 1115px 55px #767676, 757px 771px #767676,
    1003px 755px #767676, 1917px 1326px #767676, 223px 198px #767676,
    712px 1473px #767676, 566px 238px #767676, 963px 239px #767676,
    1378px 971px #767676, 151px 1159px #767676, 1560px 1633px #767676,
    900px 229px #767676, 585px 1268px #767676, 1848px 1163px #767676,
    1400px 936px #767676, 1414px 960px #767676, 1101px 1016px #767676,
    1564px 1234px #767676, 1415px 1382px #767676, 1251px 1419px #767676,
    1808px 939px #767676, 867px 684px #767676, 177px 488px #767676,
    1393px 1739px #767676, 1576px 236px #767676, 1775px 595px #767676,
    757px 36px #767676, 1692px 807px #767676, 973px 199px #767676,
    1044px 71px #767676, 1256px 801px #767676, 489px 76px #767676,
    1026px 520px #767676, 359px 1537px #767676, 1587px 855px #767676,
    518px 458px #767676, 1927px 317px #767676, 588px 1274px #767676,
    965px 967px #767676, 137px 466px #767676, 491px 685px #767676,
    1900px 453px #767676, 1912px 1498px #767676, 316px 1347px #767676,
    1700px 1567px #767676, 708px 243px #767676, 1221px 46px #767676,
    203px 1880px #767676, 477px 821px #767676, 1705px 751px #767676,
    992px 1674px #767676, 1782px 1829px #767676, 1343px 148px #767676,
    561px 1050px #767676, 673px 508px #767676, 1799px 115px #767676,
    1842px 400px #767676, 360px 351px #767676, 1798px 1191px #767676,
    1716px 609px #767676, 1171px 1453px #767676, 381px 1668px #767676,
    1943px 872px #767676, 810px 1233px #767676, 1613px 1836px #767676,
    1860px 957px #767676, 453px 1273px #767676, 1405px 1611px #767676,
    473px 1743px #767676, 1547px 529px #767676, 399px 10px #767676,
    922px 1619px #767676, 835px 1549px #767676, 83px 248px #767676,
    965px 628px #767676, 1675px 1565px #767676, 799px 617px #767676,
    1884px 10px #767676, 1166px 1493px #767676, 1864px 1408px #767676,
    149px 1061px #767676, 389px 636px #767676, 1309px 1369px #767676,
    1470px 1521px #767676, 671px 990px #767676, 1963px 591px #767676,
    931px 67px #767676, 185px 911px #767676, 543px 620px #767676,
    1123px 1229px #767676, 890px 1082px #767676, 719px 1287px #767676,
    202px 522px #767676, 1500px 1948px #767676, 1381px 1835px #767676,
    319px 293px #767676, 1428px 93px #767676, 273px 761px #767676,
    1272px 1131px #767676, 273px 775px #767676, 1186px 1566px #767676,
    1106px 888px #767676, 255px 1334px #767676, 906px 1682px #767676,
    466px 36px #767676, 587px 1257px #767676, 475px 1191px #767676,
    1451px 379px #767676, 1787px 748px #767676, 1713px 1406px #767676,
    1408px 713px #767676, 1419px 1877px #767676, 1046px 368px #767676,
    737px 1327px #767676, 1574px 760px #767676, 355px 941px #767676,
    398px 1760px #767676, 1217px 1373px #767676, 1925px 1957px #767676,
    1466px 1617px #767676, 1665px 1100px #767676, 1086px 274px #767676,
    745px 752px #767676, 778px 832px #767676, 959px 1765px #767676,
    1289px 1607px #767676, 1435px 1753px #767676, 69px 1225px #767676,
    82px 5px #767676, 973px 434px #767676, 855px 690px #767676,
    1636px 1941px #767676, 970px 1008px #767676, 34px 360px #767676,
    1453px 630px #767676, 377px 744px #767676, 1783px 854px #767676,
    1982px 1988px #767676, 1071px 1310px #767676, 564px 1391px #767676,
    75px 21px #767676, 1876px 1578px #767676, 1721px 1137px #767676,
    1695px 989px #767676, 1081px 1734px #767676, 1685px 1111px #767676,
    1513px 250px #767676, 1340px 156px #767676, 1917px 1985px #767676,
    1828px 134px #767676, 411px 513px #767676, 94px 1571px #767676,
    1908px 275px #767676, 274px 222px #767676, 705px 1202px #767676,
    1362px 1245px #767676, 1630px 796px #767676, 829px 1807px #767676,
    1062px 1194px #767676, 1236px 1467px #767676, 947px 1253px #767676,
    642px 1808px #767676, 918px 1506px #767676, 977px 1041px #767676,
    1774px 1251px #767676, 153px 954px #767676, 1633px 1406px #767676,
    191px 695px #767676, 496px 1278px #767676, 773px 714px #767676,
    662px 606px #767676, 174px 171px #767676, 1738px 517px #767676,
    1705px 1498px #767676, 308px 1338px #767676, 320px 1134px #767676,
    212px 947px #767676, 1064px 818px #767676, 981px 839px #767676,
    153px 446px #767676, 1461px 1211px #767676, 1834px 783px #767676,
    1486px 273px #767676, 437px 553px #767676, 1654px 1447px #767676,
    986px 166px #767676, 821px 1316px #767676, 601px 1371px #767676,
    355px 420px #767676, 1395px 1370px #767676, 788px 75px #767676,
    1760px 1266px #767676, 1104px 1575px #767676, 142px 574px #767676,
    897px 650px #767676, 1576px 753px #767676, 1920px 1709px #767676,
    170px 861px #767676, 1815px 215px #767676, 202px 451px #767676,
    766px 1269px #767676, 1454px 641px #767676, 197px 1358px #767676,
    1118px 1324px #767676, 526px 1346px #767676, 1127px 29px #767676,
    1776px 550px #767676, 1716px 814px #767676, 1267px 1853px #767676,
    574px 1420px #767676, 803px 546px #767676, 1038px 623px #767676,
    557px 333px #767676, 626px 608px #767676, 784px 1299px #767676,
    1730px 1857px #767676, 1810px 1226px #767676, 1672px 123px #767676,
    472px 1226px #767676, 1602px 1560px #767676, 331px 1516px #767676,
    609px 1478px #767676, 458px 1381px #767676, 1335px 581px #767676,
    1667px 941px #767676, 1586px 999px #767676, 1951px 1502px #767676,
    256px 1265px #767676, 1061px 970px #767676, 1649px 451px #767676,
    1293px 474px #767676, 886px 1780px #767676, 228px 1754px #767676,
    317px 847px #767676, 186px 1559px #767676, 174px 257px #767676,
    1624px 1957px #767676, 174px 1261px #767676, 83px 653px #767676,
    295px 697px #767676, 466px 1839px #767676, 923px 209px #767676,
    704px 1430px #767676, 24px 1003px #767676, 1135px 1174px #767676,
    1405px 210px #767676, 1901px 1514px #767676, 790px 1718px #767676,
    1534px 534px #767676, 1029px 822px #767676, 1955px 1703px #767676,
    209px 25px #767676, 1803px 1746px #767676, 642px 831px #767676,
    1052px 2000px #767676, 1700px 645px #767676, 1588px 1844px #767676,
    378px 1695px #767676, 1057px 668px #767676, 1926px 1006px #767676,
    1424px 107px #767676, 286px 1334px #767676, 1595px 869px #767676,
    741px 1446px #767676, 877px 1980px #767676, 1102px 1412px #767676,
    1541px 598px #767676, 1075px 508px #767676, 525px 1480px #767676,
    1059px 378px #767676, 1177px 1482px #767676, 1355px 388px #767676,
    1669px 844px #767676, 657px 1639px #767676, 912px 763px #767676,
    784px 687px #767676, 917px 1856px #767676, 81px 608px #767676,
    340px 696px #767676, 1120px 1495px #767676, 977px 1627px #767676,
    1620px 349px #767676, 140px 380px #767676, 458px 1318px #767676,
    282px 177px #767676, 1668px 167px #767676, 897px 477px #767676,
    719px 66px #767676, 797px 1027px #767676, 1821px 918px #767676,
    226px 1132px #767676, 606px 382px #767676, 1222px 107px #767676,
    1170px 1767px #767676, 391px 634px #767676, 523px 103px #767676,
    612px 1330px #767676, 348px 211px #767676, 1711px 1802px #767676,
    271px 1583px #767676, 1606px 1861px #767676, 1292px 266px #767676,
    1602px 847px #767676, 150px 994px #767676, 860px 199px #767676,
    483px 185px #767676, 970px 241px #767676, 154px 294px #767676,
    1670px 1012px #767676, 419px 593px #767676, 550px 1229px #767676,
    1404px 296px #767676, 1600px 414px #767676, 1086px 109px #767676,
    773px 240px #767676, 636px 1539px #767676, 485px 650px #767676,
    223px 1989px #767676, 900px 1264px #767676, 67px 1860px #767676,
    77px 756px #767676, 1131px 1006px #767676, 1929px 1755px #767676,
    69px 1595px #767676, 109px 1376px #767676, 896px 1278px #767676,
    328px 117px #767676, 1705px 802px #767676, 655px 597px #767676,
    603px 1114px #767676, 1637px 994px #767676, 1142px 1382px #767676,
    35px 745px #767676, 258px 1847px #767676, 54px 178px #767676,
    973px 617px #767676, 97px 1406px #767676, 669px 1367px #767676,
    1803px 1266px #767676, 441px 1603px #767676, 1023px 1636px #767676,
    151px 1706px #767676, 711px 1923px #767676, 1533px 1782px #767676,
    1023px 689px #767676, 1461px 1659px #767676, 1442px 913px #767676,
    1577px 1952px #767676, 1864px 705px #767676, 1545px 1725px #767676,
    884px 1150px #767676, 1594px 1407px #767676, 1077px 1490px #767676,
    297px 1662px #767676, 812px 71px #767676, 105px 891px #767676,
    42px 830px #767676, 456px 797px #767676, 1355px 424px #767676,
    63px 215px #767676, 821px 343px #767676, 976px 317px #767676,
    1182px 1721px #767676, 1312px 200px #767676, 1461px 1563px #767676,
    1972px 1925px #767676, 546px 241px #767676, 833px 726px #767676,
    1816px 1800px #767676, 1867px 129px #767676, 1171px 966px #767676,
    1956px 945px #767676, 977px 1520px #767676, 647px 1737px #767676,
    1505px 1471px #767676, 360px 1231px #767676, 453px 81px #767676,
    1077px 1861px #767676, 552px 958px #767676, 417px 1611px #767676,
    3px 1731px #767676, 1155px 1081px #767676, 734px 1895px #767676,
    1362px 450px #767676, 51px 902px #767676, 226px 938px #767676,
    535px 1543px #767676, 1820px 1077px #767676, 1667px 929px #767676,
    674px 280px #767676, 1688px 290px #767676, 1215px 1278px #767676,
    222px 1700px #767676, 1948px 1485px #767676, 531px 1926px #767676,
    527px 1615px #767676, 1555px 1575px #767676, 1266px 1501px #767676,
    339px 1920px #767676, 847px 846px #767676, 215px 1407px #767676,
    171px 1830px #767676, 587px 957px #767676, 161px 365px #767676,
    1475px 508px #767676, 786px 1536px #767676, 101px 1819px #767676,
    926px 1689px #767676, 1874px 350px #767676, 535px 1273px #767676,
    1744px 1427px #767676, 110px 553px #767676, 348px 51px #767676,
    704px 462px #767676, 165px 1939px #767676, 179px 1263px #767676,
    255px 807px #767676, 959px 507px #767676, 511px 874px #767676,
    934px 1371px #767676, 115px 1655px #767676, 1311px 1153px #767676,
    826px 1970px #767676, 189px 650px #767676, 111px 630px #767676,
    1444px 1564px #767676, 1938px 1117px #767676, 1958px 445px #767676,
    1698px 82px #767676, 4px 1190px #767676, 1330px 54px #767676,
    1120px 1728px #767676, 98px 799px #767676, 1890px 1663px #767676,
    1439px 1229px #767676, 368px 361px #767676, 415px 1697px #767676,
    1256px 1905px #767676, 850px 653px #767676, 1109px 1722px #767676,
    1781px 1244px #767676, 842px 248px #767676, 1692px 1647px #767676,
    1328px 79px #767676, 1175px 784px #767676, 893px 695px #767676,
    151px 1161px #767676, 1311px 1956px #767676, 1015px 644px #767676,
    1753px 1426px #767676, 474px 1448px #767676, 1018px 440px #767676,
    1297px 225px #767676, 1973px 201px #767676, 2px 1642px #767676,
    1926px 1718px #767676, 48px 1653px #767676, 343px 1173px #767676,
    1569px 855px #767676, 929px 1668px #767676, 1309px 927px #767676,
    1996px 565px #767676, 266px 632px #767676, 1686px 726px #767676,
    1068px 1405px #767676, 1289px 258px #767676, 842px 130px #767676,
    572px 392px #767676, 787px 1748px #767676, 1552px 269px #767676,
    343px 1432px #767676, 1502px 1555px #767676, 1609px 954px #767676,
    1344px 1246px #767676, 727px 1412px #767676, 25px 31px #767676,
    1362px 193px #767676, 954px 1546px #767676, 1310px 415px #767676,
    1061px 1707px #767676, 40px 1574px #767676, 1228px 1626px #767676,
    1502px 1520px #767676, 117px 1968px #767676, 224px 576px #767676,
    1463px 1158px #767676, 1580px 1397px #767676, 723px 662px #767676,
    242px 1096px #767676, 970px 1797px #767676, 702px 812px #767676,
    1281px 612px #767676, 1318px 221px #767676, 590px 404px #767676,
    1691px 929px #767676, 1805px 915px #767676, 1876px 627px #767676,
    1954px 1776px #767676, 1680px 1843px #767676, 567px 293px #767676,
    1367px 1511px #767676, 1372px 994px #767676, 1141px 660px #767676,
    1806px 1578px #767676, 1450px 1059px #767676;
  animation: starsAnimation 50s linear infinite;
}
.stars > .small:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1434px 1998px #767676, 1855px 1916px #767676,
    1527px 1420px #767676, 887px 471px #767676, 1482px 127px #767676,
    1369px 365px #767676, 264px 342px #767676, 38px 967px #767676,
    723px 340px #767676, 1990px 260px #767676, 1443px 131px #767676,
    729px 1119px #767676, 302px 1290px #767676, 1596px 76px #767676,
    432px 468px #767676, 154px 860px #767676, 531px 438px #767676,
    1843px 1699px #767676, 546px 964px #767676, 1161px 999px #767676,
    483px 1529px #767676, 730px 771px #767676, 1604px 725px #767676,
    1012px 8px #767676, 1608px 276px #767676, 963px 682px #767676,
    931px 1075px #767676, 713px 448px #767676, 973px 306px #767676,
    389px 193px #767676, 1278px 177px #767676, 1092px 836px #767676,
    876px 547px #767676, 134px 1276px #767676, 341px 1123px #767676,
    625px 1744px #767676, 688px 1927px #767676, 1231px 959px #767676,
    1107px 147px #767676, 1725px 1445px #767676, 1079px 222px #767676,
    1171px 486px #767676, 1719px 1754px #767676, 183px 1367px #767676,
    1444px 177px #767676, 292px 933px #767676, 1450px 133px #767676,
    463px 135px #767676, 884px 300px #767676, 1292px 628px #767676,
    1789px 133px #767676, 1320px 1913px #767676, 283px 1462px #767676,
    1220px 1847px #767676, 1788px 260px #767676, 1108px 1349px #767676,
    355px 26px #767676, 1214px 380px #767676, 1189px 345px #767676,
    1403px 1908px #767676, 61px 1951px #767676, 250px 1495px #767676,
    1808px 944px #767676, 918px 852px #767676, 1242px 266px #767676,
    1376px 288px #767676, 352px 1900px #767676, 382px 1897px #767676,
    332px 444px #767676, 1597px 379px #767676, 331px 737px #767676,
    1047px 1254px #767676, 512px 237px #767676, 91px 1450px #767676,
    1623px 171px #767676, 597px 1337px #767676, 652px 1430px #767676,
    460px 238px #767676, 482px 1950px #767676, 1508px 1188px #767676,
    1781px 980px #767676, 659px 718px #767676, 276px 1231px #767676,
    1138px 1585px #767676, 1765px 429px #767676, 121px 1117px #767676,
    1742px 702px #767676, 922px 982px #767676, 1995px 1424px #767676,
    250px 1272px #767676, 841px 965px #767676, 197px 1036px #767676,
    374px 1596px #767676, 913px 1800px #767676, 1191px 1803px #767676,
    1033px 876px #767676, 1811px 1054px #767676, 651px 441px #767676,
    1155px 1765px #767676, 23px 483px #767676, 1892px 975px #767676,
    30px 1248px #767676, 1316px 220px #767676, 1130px 1919px #767676,
    1566px 762px #767676, 533px 742px #767676, 1958px 1511px #767676,
    1185px 3px #767676, 314px 1114px #767676, 108px 568px #767676,
    411px 1751px #767676, 1877px 1160px #767676, 538px 1583px #767676,
    1362px 377px #767676, 500px 428px #767676, 1091px 1167px #767676,
    1678px 1117px #767676, 757px 1934px #767676, 669px 458px #767676,
    1456px 1710px #767676, 1292px 471px #767676, 1755px 1490px #767676,
    495px 1118px #767676, 494px 586px #767676, 717px 1425px #767676,
    624px 1144px #767676, 486px 901px #767676, 1562px 1112px #767676,
    1282px 719px #767676, 48px 1984px #767676, 326px 1061px #767676,
    1780px 109px #767676, 1171px 1242px #767676, 426px 537px #767676,
    118px 1976px #767676, 1214px 1573px #767676, 1862px 539px #767676,
    1045px 635px #767676, 844px 1324px #767676, 937px 439px #767676,
    862px 712px #767676, 1674px 1891px #767676, 1266px 802px #767676,
    1145px 342px #767676, 1781px 1951px #767676, 1458px 139px #767676,
    951px 593px #767676, 1172px 1874px #767676, 1646px 1752px #767676,
    767px 1793px #767676, 1639px 112px #767676, 114px 1179px #767676,
    878px 1322px #767676, 1455px 1121px #767676, 969px 1048px #767676,
    1383px 875px #767676, 721px 1098px #767676, 354px 1838px #767676,
    875px 1481px #767676, 1194px 1765px #767676, 1835px 1969px #767676,
    630px 204px #767676, 1644px 1129px #767676, 1274px 263px #767676,
    1549px 746px #767676, 816px 1686px #767676, 945px 343px #767676,
    385px 1913px #767676, 622px 1059px #767676, 1355px 151px #767676,
    1744px 488px #767676, 1899px 251px #767676, 171px 409px #767676,
    923px 657px #767676, 1769px 1585px #767676, 231px 1675px #767676,
    1169px 931px #767676, 1753px 1239px #767676, 893px 1020px #767676,
    1745px 1891px #767676, 1419px 98px #767676, 428px 1137px #767676,
    1805px 1588px #767676, 1810px 1758px #767676, 1934px 1868px #767676,
    496px 925px #767676, 1572px 125px #767676, 11px 861px #767676,
    1594px 1484px #767676, 927px 1848px #767676, 1276px 485px #767676,
    1897px 1565px #767676, 1971px 109px #767676, 264px 1318px #767676,
    684px 1589px #767676, 1115px 55px #767676, 757px 771px #767676,
    1003px 755px #767676, 1917px 1326px #767676, 223px 198px #767676,
    712px 1473px #767676, 566px 238px #767676, 963px 239px #767676,
    1378px 971px #767676, 151px 1159px #767676, 1560px 1633px #767676,
    900px 229px #767676, 585px 1268px #767676, 1848px 1163px #767676,
    1400px 936px #767676, 1414px 960px #767676, 1101px 1016px #767676,
    1564px 1234px #767676, 1415px 1382px #767676, 1251px 1419px #767676,
    1808px 939px #767676, 867px 684px #767676, 177px 488px #767676,
    1393px 1739px #767676, 1576px 236px #767676, 1775px 595px #767676,
    757px 36px #767676, 1692px 807px #767676, 973px 199px #767676,
    1044px 71px #767676, 1256px 801px #767676, 489px 76px #767676,
    1026px 520px #767676, 359px 1537px #767676, 1587px 855px #767676,
    518px 458px #767676, 1927px 317px #767676, 588px 1274px #767676,
    965px 967px #767676, 137px 466px #767676, 491px 685px #767676,
    1900px 453px #767676, 1912px 1498px #767676, 316px 1347px #767676,
    1700px 1567px #767676, 708px 243px #767676, 1221px 46px #767676,
    203px 1880px #767676, 477px 821px #767676, 1705px 751px #767676,
    992px 1674px #767676, 1782px 1829px #767676, 1343px 148px #767676,
    561px 1050px #767676, 673px 508px #767676, 1799px 115px #767676,
    1842px 400px #767676, 360px 351px #767676, 1798px 1191px #767676,
    1716px 609px #767676, 1171px 1453px #767676, 381px 1668px #767676,
    1943px 872px #767676, 810px 1233px #767676, 1613px 1836px #767676,
    1860px 957px #767676, 453px 1273px #767676, 1405px 1611px #767676,
    473px 1743px #767676, 1547px 529px #767676, 399px 10px #767676,
    922px 1619px #767676, 835px 1549px #767676, 83px 248px #767676,
    965px 628px #767676, 1675px 1565px #767676, 799px 617px #767676,
    1884px 10px #767676, 1166px 1493px #767676, 1864px 1408px #767676,
    149px 1061px #767676, 389px 636px #767676, 1309px 1369px #767676,
    1470px 1521px #767676, 671px 990px #767676, 1963px 591px #767676,
    931px 67px #767676, 185px 911px #767676, 543px 620px #767676,
    1123px 1229px #767676, 890px 1082px #767676, 719px 1287px #767676,
    202px 522px #767676, 1500px 1948px #767676, 1381px 1835px #767676,
    319px 293px #767676, 1428px 93px #767676, 273px 761px #767676,
    1272px 1131px #767676, 273px 775px #767676, 1186px 1566px #767676,
    1106px 888px #767676, 255px 1334px #767676, 906px 1682px #767676,
    466px 36px #767676, 587px 1257px #767676, 475px 1191px #767676,
    1451px 379px #767676, 1787px 748px #767676, 1713px 1406px #767676,
    1408px 713px #767676, 1419px 1877px #767676, 1046px 368px #767676,
    737px 1327px #767676, 1574px 760px #767676, 355px 941px #767676,
    398px 1760px #767676, 1217px 1373px #767676, 1925px 1957px #767676,
    1466px 1617px #767676, 1665px 1100px #767676, 1086px 274px #767676,
    745px 752px #767676, 778px 832px #767676, 959px 1765px #767676,
    1289px 1607px #767676, 1435px 1753px #767676, 69px 1225px #767676,
    82px 5px #767676, 973px 434px #767676, 855px 690px #767676,
    1636px 1941px #767676, 970px 1008px #767676, 34px 360px #767676,
    1453px 630px #767676, 377px 744px #767676, 1783px 854px #767676,
    1982px 1988px #767676, 1071px 1310px #767676, 564px 1391px #767676,
    75px 21px #767676, 1876px 1578px #767676, 1721px 1137px #767676,
    1695px 989px #767676, 1081px 1734px #767676, 1685px 1111px #767676,
    1513px 250px #767676, 1340px 156px #767676, 1917px 1985px #767676,
    1828px 134px #767676, 411px 513px #767676, 94px 1571px #767676,
    1908px 275px #767676, 274px 222px #767676, 705px 1202px #767676,
    1362px 1245px #767676, 1630px 796px #767676, 829px 1807px #767676,
    1062px 1194px #767676, 1236px 1467px #767676, 947px 1253px #767676,
    642px 1808px #767676, 918px 1506px #767676, 977px 1041px #767676,
    1774px 1251px #767676, 153px 954px #767676, 1633px 1406px #767676,
    191px 695px #767676, 496px 1278px #767676, 773px 714px #767676,
    662px 606px #767676, 174px 171px #767676, 1738px 517px #767676,
    1705px 1498px #767676, 308px 1338px #767676, 320px 1134px #767676,
    212px 947px #767676, 1064px 818px #767676, 981px 839px #767676,
    153px 446px #767676, 1461px 1211px #767676, 1834px 783px #767676,
    1486px 273px #767676, 437px 553px #767676, 1654px 1447px #767676,
    986px 166px #767676, 821px 1316px #767676, 601px 1371px #767676,
    355px 420px #767676, 1395px 1370px #767676, 788px 75px #767676,
    1760px 1266px #767676, 1104px 1575px #767676, 142px 574px #767676,
    897px 650px #767676, 1576px 753px #767676, 1920px 1709px #767676,
    170px 861px #767676, 1815px 215px #767676, 202px 451px #767676,
    766px 1269px #767676, 1454px 641px #767676, 197px 1358px #767676,
    1118px 1324px #767676, 526px 1346px #767676, 1127px 29px #767676,
    1776px 550px #767676, 1716px 814px #767676, 1267px 1853px #767676,
    574px 1420px #767676, 803px 546px #767676, 1038px 623px #767676,
    557px 333px #767676, 626px 608px #767676, 784px 1299px #767676,
    1730px 1857px #767676, 1810px 1226px #767676, 1672px 123px #767676,
    472px 1226px #767676, 1602px 1560px #767676, 331px 1516px #767676,
    609px 1478px #767676, 458px 1381px #767676, 1335px 581px #767676,
    1667px 941px #767676, 1586px 999px #767676, 1951px 1502px #767676,
    256px 1265px #767676, 1061px 970px #767676, 1649px 451px #767676,
    1293px 474px #767676, 886px 1780px #767676, 228px 1754px #767676,
    317px 847px #767676, 186px 1559px #767676, 174px 257px #767676,
    1624px 1957px #767676, 174px 1261px #767676, 83px 653px #767676,
    295px 697px #767676, 466px 1839px #767676, 923px 209px #767676,
    704px 1430px #767676, 24px 1003px #767676, 1135px 1174px #767676,
    1405px 210px #767676, 1901px 1514px #767676, 790px 1718px #767676,
    1534px 534px #767676, 1029px 822px #767676, 1955px 1703px #767676,
    209px 25px #767676, 1803px 1746px #767676, 642px 831px #767676,
    1052px 2000px #767676, 1700px 645px #767676, 1588px 1844px #767676,
    378px 1695px #767676, 1057px 668px #767676, 1926px 1006px #767676,
    1424px 107px #767676, 286px 1334px #767676, 1595px 869px #767676,
    741px 1446px #767676, 877px 1980px #767676, 1102px 1412px #767676,
    1541px 598px #767676, 1075px 508px #767676, 525px 1480px #767676,
    1059px 378px #767676, 1177px 1482px #767676, 1355px 388px #767676,
    1669px 844px #767676, 657px 1639px #767676, 912px 763px #767676,
    784px 687px #767676, 917px 1856px #767676, 81px 608px #767676,
    340px 696px #767676, 1120px 1495px #767676, 977px 1627px #767676,
    1620px 349px #767676, 140px 380px #767676, 458px 1318px #767676,
    282px 177px #767676, 1668px 167px #767676, 897px 477px #767676,
    719px 66px #767676, 797px 1027px #767676, 1821px 918px #767676,
    226px 1132px #767676, 606px 382px #767676, 1222px 107px #767676,
    1170px 1767px #767676, 391px 634px #767676, 523px 103px #767676,
    612px 1330px #767676, 348px 211px #767676, 1711px 1802px #767676,
    271px 1583px #767676, 1606px 1861px #767676, 1292px 266px #767676,
    1602px 847px #767676, 150px 994px #767676, 860px 199px #767676,
    483px 185px #767676, 970px 241px #767676, 154px 294px #767676,
    1670px 1012px #767676, 419px 593px #767676, 550px 1229px #767676,
    1404px 296px #767676, 1600px 414px #767676, 1086px 109px #767676,
    773px 240px #767676, 636px 1539px #767676, 485px 650px #767676,
    223px 1989px #767676, 900px 1264px #767676, 67px 1860px #767676,
    77px 756px #767676, 1131px 1006px #767676, 1929px 1755px #767676,
    69px 1595px #767676, 109px 1376px #767676, 896px 1278px #767676,
    328px 117px #767676, 1705px 802px #767676, 655px 597px #767676,
    603px 1114px #767676, 1637px 994px #767676, 1142px 1382px #767676,
    35px 745px #767676, 258px 1847px #767676, 54px 178px #767676,
    973px 617px #767676, 97px 1406px #767676, 669px 1367px #767676,
    1803px 1266px #767676, 441px 1603px #767676, 1023px 1636px #767676,
    151px 1706px #767676, 711px 1923px #767676, 1533px 1782px #767676,
    1023px 689px #767676, 1461px 1659px #767676, 1442px 913px #767676,
    1577px 1952px #767676, 1864px 705px #767676, 1545px 1725px #767676,
    884px 1150px #767676, 1594px 1407px #767676, 1077px 1490px #767676,
    297px 1662px #767676, 812px 71px #767676, 105px 891px #767676,
    42px 830px #767676, 456px 797px #767676, 1355px 424px #767676,
    63px 215px #767676, 821px 343px #767676, 976px 317px #767676,
    1182px 1721px #767676, 1312px 200px #767676, 1461px 1563px #767676,
    1972px 1925px #767676, 546px 241px #767676, 833px 726px #767676,
    1816px 1800px #767676, 1867px 129px #767676, 1171px 966px #767676,
    1956px 945px #767676, 977px 1520px #767676, 647px 1737px #767676,
    1505px 1471px #767676, 360px 1231px #767676, 453px 81px #767676,
    1077px 1861px #767676, 552px 958px #767676, 417px 1611px #767676,
    3px 1731px #767676, 1155px 1081px #767676, 734px 1895px #767676,
    1362px 450px #767676, 51px 902px #767676, 226px 938px #767676,
    535px 1543px #767676, 1820px 1077px #767676, 1667px 929px #767676,
    674px 280px #767676, 1688px 290px #767676, 1215px 1278px #767676,
    222px 1700px #767676, 1948px 1485px #767676, 531px 1926px #767676,
    527px 1615px #767676, 1555px 1575px #767676, 1266px 1501px #767676,
    339px 1920px #767676, 847px 846px #767676, 215px 1407px #767676,
    171px 1830px #767676, 587px 957px #767676, 161px 365px #767676,
    1475px 508px #767676, 786px 1536px #767676, 101px 1819px #767676,
    926px 1689px #767676, 1874px 350px #767676, 535px 1273px #767676,
    1744px 1427px #767676, 110px 553px #767676, 348px 51px #767676,
    704px 462px #767676, 165px 1939px #767676, 179px 1263px #767676,
    255px 807px #767676, 959px 507px #767676, 511px 874px #767676,
    934px 1371px #767676, 115px 1655px #767676, 1311px 1153px #767676,
    826px 1970px #767676, 189px 650px #767676, 111px 630px #767676,
    1444px 1564px #767676, 1938px 1117px #767676, 1958px 445px #767676,
    1698px 82px #767676, 4px 1190px #767676, 1330px 54px #767676,
    1120px 1728px #767676, 98px 799px #767676, 1890px 1663px #767676,
    1439px 1229px #767676, 368px 361px #767676, 415px 1697px #767676,
    1256px 1905px #767676, 850px 653px #767676, 1109px 1722px #767676,
    1781px 1244px #767676, 842px 248px #767676, 1692px 1647px #767676,
    1328px 79px #767676, 1175px 784px #767676, 893px 695px #767676,
    151px 1161px #767676, 1311px 1956px #767676, 1015px 644px #767676,
    1753px 1426px #767676, 474px 1448px #767676, 1018px 440px #767676,
    1297px 225px #767676, 1973px 201px #767676, 2px 1642px #767676,
    1926px 1718px #767676, 48px 1653px #767676, 343px 1173px #767676,
    1569px 855px #767676, 929px 1668px #767676, 1309px 927px #767676,
    1996px 565px #767676, 266px 632px #767676, 1686px 726px #767676,
    1068px 1405px #767676, 1289px 258px #767676, 842px 130px #767676,
    572px 392px #767676, 787px 1748px #767676, 1552px 269px #767676,
    343px 1432px #767676, 1502px 1555px #767676, 1609px 954px #767676,
    1344px 1246px #767676, 727px 1412px #767676, 25px 31px #767676,
    1362px 193px #767676, 954px 1546px #767676, 1310px 415px #767676,
    1061px 1707px #767676, 40px 1574px #767676, 1228px 1626px #767676,
    1502px 1520px #767676, 117px 1968px #767676, 224px 576px #767676,
    1463px 1158px #767676, 1580px 1397px #767676, 723px 662px #767676,
    242px 1096px #767676, 970px 1797px #767676, 702px 812px #767676,
    1281px 612px #767676, 1318px 221px #767676, 590px 404px #767676,
    1691px 929px #767676, 1805px 915px #767676, 1876px 627px #767676,
    1954px 1776px #767676, 1680px 1843px #767676, 567px 293px #767676,
    1367px 1511px #767676, 1372px 994px #767676, 1141px 660px #767676,
    1806px 1578px #767676, 1450px 1059px #767676;
}

.stars > .medium {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 604px 1699px #767676, 11px 238px #767676, 1950px 1517px #767676,
    1440px 1049px #767676, 260px 374px #767676, 1307px 892px #767676,
    1371px 820px #767676, 1992px 1528px #767676, 860px 1378px #767676,
    946px 1493px #767676, 1441px 81px #767676, 128px 285px #767676,
    1075px 594px #767676, 316px 1112px #767676, 90px 1895px #767676,
    889px 1250px #767676, 847px 1360px #767676, 1983px 499px #767676,
    1138px 1630px #767676, 456px 707px #767676, 831px 571px #767676,
    680px 1356px #767676, 350px 271px #767676, 985px 820px #767676,
    1896px 551px #767676, 1921px 1384px #767676, 1256px 1794px #767676,
    1644px 1251px #767676, 206px 1885px #767676, 1200px 422px #767676,
    1953px 1218px #767676, 1498px 194px #767676, 1097px 1359px #767676,
    181px 1967px #767676, 1659px 1542px #767676, 1677px 985px #767676,
    1557px 1632px #767676, 197px 1594px #767676, 1425px 275px #767676,
    501px 541px #767676, 972px 773px #767676, 1710px 1305px #767676,
    628px 1558px #767676, 691px 673px #767676, 1766px 1px #767676,
    1100px 1198px #767676, 506px 1013px #767676, 153px 1768px #767676,
    1539px 56px #767676, 396px 1880px #767676, 182px 1611px #767676,
    38px 1251px #767676, 1750px 1400px #767676, 1535px 2px #767676,
    965px 1044px #767676, 1570px 557px #767676, 1992px 451px #767676,
    1774px 172px #767676, 1449px 201px #767676, 169px 1311px #767676,
    475px 1618px #767676, 318px 1794px #767676, 267px 745px #767676,
    791px 1505px #767676, 884px 1537px #767676, 1038px 292px #767676,
    874px 1613px #767676, 215px 1816px #767676, 176px 1818px #767676,
    1581px 22px #767676, 528px 1038px #767676, 1910px 931px #767676,
    1427px 327px #767676, 1978px 52px #767676, 1784px 187px #767676,
    1073px 59px #767676, 1290px 1769px #767676, 1231px 666px #767676,
    1867px 1154px #767676, 514px 1916px #767676, 1397px 915px #767676,
    59px 1644px #767676, 188px 494px #767676, 1769px 913px #767676,
    404px 868px #767676, 1869px 351px #767676, 1127px 759px #767676,
    1150px 1964px #767676, 1867px 917px #767676, 1921px 249px #767676,
    1007px 1429px #767676, 835px 1573px #767676, 1220px 717px #767676,
    264px 1017px #767676, 1508px 1956px #767676, 1747px 438px #767676,
    1981px 1343px #767676, 1490px 577px #767676, 1102px 375px #767676,
    1377px 1649px #767676, 591px 1913px #767676, 1028px 1765px #767676,
    1483px 1040px #767676, 416px 122px #767676, 269px 286px #767676,
    586px 199px #767676, 875px 1613px #767676, 800px 609px #767676,
    280px 1790px #767676, 1663px 1854px #767676, 747px 688px #767676,
    1358px 362px #767676, 1222px 1160px #767676, 974px 1769px #767676,
    405px 1160px #767676, 1928px 852px #767676, 1781px 1555px #767676,
    68px 1607px #767676, 1579px 328px #767676, 1938px 217px #767676,
    898px 661px #767676, 909px 1058px #767676, 1797px 1053px #767676,
    859px 260px #767676, 1975px 538px #767676, 303px 1106px #767676,
    607px 1014px #767676, 1810px 1149px #767676, 1346px 680px #767676,
    1576px 1678px #767676, 597px 554px #767676, 446px 836px #767676,
    1770px 904px #767676, 1593px 493px #767676, 878px 1094px #767676,
    1009px 1870px #767676, 1038px 1606px #767676, 679px 914px #767676,
    1979px 795px #767676, 897px 1903px #767676, 1605px 1374px #767676,
    234px 448px #767676, 72px 398px #767676, 1257px 887px #767676,
    588px 1224px #767676, 653px 999px #767676, 648px 780px #767676,
    111px 1096px #767676, 1286px 1554px #767676, 32px 709px #767676,
    1984px 1908px #767676, 599px 1226px #767676, 413px 1384px #767676,
    705px 1463px #767676, 591px 572px #767676, 388px 119px #767676,
    228px 1090px #767676, 803px 1763px #767676, 1359px 1140px #767676,
    638px 384px #767676, 36px 1402px #767676, 1199px 1540px #767676,
    293px 1171px #767676, 425px 1817px #767676, 1081px 806px #767676,
    1796px 1496px #767676, 61px 408px #767676, 580px 649px #767676,
    1843px 1306px #767676, 47px 266px #767676, 1265px 1566px #767676,
    921px 1453px #767676, 731px 1856px #767676, 1168px 1192px #767676,
    1900px 1240px #767676, 1026px 1536px #767676, 737px 1477px #767676,
    94px 686px #767676, 1017px 217px #767676, 278px 765px #767676,
    1132px 268px #767676, 850px 548px #767676, 1493px 228px #767676,
    1589px 1070px #767676, 1102px 1659px #767676, 1124px 271px #767676,
    438px 288px #767676, 1614px 437px #767676, 387px 1675px #767676,
    406px 151px #767676, 591px 755px #767676, 987px 1962px #767676,
    1405px 391px #767676, 1433px 1020px #767676, 21px 616px #767676,
    1341px 516px #767676, 346px 1985px #767676, 736px 806px #767676,
    1066px 1024px #767676, 347px 827px #767676;
  animation: starsAnimation 100s linear infinite;
}
.stars > .medium:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 604px 1699px #767676, 11px 238px #767676, 1950px 1517px #767676,
    1440px 1049px #767676, 260px 374px #767676, 1307px 892px #767676,
    1371px 820px #767676, 1992px 1528px #767676, 860px 1378px #767676,
    946px 1493px #767676, 1441px 81px #767676, 128px 285px #767676,
    1075px 594px #767676, 316px 1112px #767676, 90px 1895px #767676,
    889px 1250px #767676, 847px 1360px #767676, 1983px 499px #767676,
    1138px 1630px #767676, 456px 707px #767676, 831px 571px #767676,
    680px 1356px #767676, 350px 271px #767676, 985px 820px #767676,
    1896px 551px #767676, 1921px 1384px #767676, 1256px 1794px #767676,
    1644px 1251px #767676, 206px 1885px #767676, 1200px 422px #767676,
    1953px 1218px #767676, 1498px 194px #767676, 1097px 1359px #767676,
    181px 1967px #767676, 1659px 1542px #767676, 1677px 985px #767676,
    1557px 1632px #767676, 197px 1594px #767676, 1425px 275px #767676,
    501px 541px #767676, 972px 773px #767676, 1710px 1305px #767676,
    628px 1558px #767676, 691px 673px #767676, 1766px 1px #767676,
    1100px 1198px #767676, 506px 1013px #767676, 153px 1768px #767676,
    1539px 56px #767676, 396px 1880px #767676, 182px 1611px #767676,
    38px 1251px #767676, 1750px 1400px #767676, 1535px 2px #767676,
    965px 1044px #767676, 1570px 557px #767676, 1992px 451px #767676,
    1774px 172px #767676, 1449px 201px #767676, 169px 1311px #767676,
    475px 1618px #767676, 318px 1794px #767676, 267px 745px #767676,
    791px 1505px #767676, 884px 1537px #767676, 1038px 292px #767676,
    874px 1613px #767676, 215px 1816px #767676, 176px 1818px #767676,
    1581px 22px #767676, 528px 1038px #767676, 1910px 931px #767676,
    1427px 327px #767676, 1978px 52px #767676, 1784px 187px #767676,
    1073px 59px #767676, 1290px 1769px #767676, 1231px 666px #767676,
    1867px 1154px #767676, 514px 1916px #767676, 1397px 915px #767676,
    59px 1644px #767676, 188px 494px #767676, 1769px 913px #767676,
    404px 868px #767676, 1869px 351px #767676, 1127px 759px #767676,
    1150px 1964px #767676, 1867px 917px #767676, 1921px 249px #767676,
    1007px 1429px #767676, 835px 1573px #767676, 1220px 717px #767676,
    264px 1017px #767676, 1508px 1956px #767676, 1747px 438px #767676,
    1981px 1343px #767676, 1490px 577px #767676, 1102px 375px #767676,
    1377px 1649px #767676, 591px 1913px #767676, 1028px 1765px #767676,
    1483px 1040px #767676, 416px 122px #767676, 269px 286px #767676,
    586px 199px #767676, 875px 1613px #767676, 800px 609px #767676,
    280px 1790px #767676, 1663px 1854px #767676, 747px 688px #767676,
    1358px 362px #767676, 1222px 1160px #767676, 974px 1769px #767676,
    405px 1160px #767676, 1928px 852px #767676, 1781px 1555px #767676,
    68px 1607px #767676, 1579px 328px #767676, 1938px 217px #767676,
    898px 661px #767676, 909px 1058px #767676, 1797px 1053px #767676,
    859px 260px #767676, 1975px 538px #767676, 303px 1106px #767676,
    607px 1014px #767676, 1810px 1149px #767676, 1346px 680px #767676,
    1576px 1678px #767676, 597px 554px #767676, 446px 836px #767676,
    1770px 904px #767676, 1593px 493px #767676, 878px 1094px #767676,
    1009px 1870px #767676, 1038px 1606px #767676, 679px 914px #767676,
    1979px 795px #767676, 897px 1903px #767676, 1605px 1374px #767676,
    234px 448px #767676, 72px 398px #767676, 1257px 887px #767676,
    588px 1224px #767676, 653px 999px #767676, 648px 780px #767676,
    111px 1096px #767676, 1286px 1554px #767676, 32px 709px #767676,
    1984px 1908px #767676, 599px 1226px #767676, 413px 1384px #767676,
    705px 1463px #767676, 591px 572px #767676, 388px 119px #767676,
    228px 1090px #767676, 803px 1763px #767676, 1359px 1140px #767676,
    638px 384px #767676, 36px 1402px #767676, 1199px 1540px #767676,
    293px 1171px #767676, 425px 1817px #767676, 1081px 806px #767676,
    1796px 1496px #767676, 61px 408px #767676, 580px 649px #767676,
    1843px 1306px #767676, 47px 266px #767676, 1265px 1566px #767676,
    921px 1453px #767676, 731px 1856px #767676, 1168px 1192px #767676,
    1900px 1240px #767676, 1026px 1536px #767676, 737px 1477px #767676,
    94px 686px #767676, 1017px 217px #767676, 278px 765px #767676,
    1132px 268px #767676, 850px 548px #767676, 1493px 228px #767676,
    1589px 1070px #767676, 1102px 1659px #767676, 1124px 271px #767676,
    438px 288px #767676, 1614px 437px #767676, 387px 1675px #767676,
    406px 151px #767676, 591px 755px #767676, 987px 1962px #767676,
    1405px 391px #767676, 1433px 1020px #767676, 21px 616px #767676,
    1341px 516px #767676, 346px 1985px #767676, 736px 806px #767676,
    1066px 1024px #767676, 347px 827px #767676;
}

.stars > .big {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 40px 1463px #767676, 1666px 1259px #767676, 374px 544px #767676,
    301px 143px #767676, 585px 1229px #767676, 1511px 1364px #767676,
    1036px 350px #767676, 1061px 1815px #767676, 660px 1005px #767676,
    846px 1633px #767676, 350px 749px #767676, 1954px 1678px #767676,
    1012px 446px #767676, 604px 1675px #767676, 1410px 253px #767676,
    385px 1170px #767676, 1943px 1695px #767676, 1513px 1271px #767676,
    1649px 1261px #767676, 810px 177px #767676, 977px 589px #767676,
    882px 94px #767676, 1298px 1113px #767676, 903px 1577px #767676,
    1980px 1354px #767676, 1884px 1534px #767676, 770px 1429px #767676,
    273px 1234px #767676, 56px 150px #767676, 1216px 711px #767676,
    795px 1326px #767676, 521px 1503px #767676, 1995px 633px #767676,
    667px 421px #767676, 533px 675px #767676, 1568px 1815px #767676,
    152px 877px #767676, 1672px 208px #767676, 1256px 1424px #767676,
    145px 791px #767676, 778px 1715px #767676, 49px 544px #767676,
    1627px 188px #767676, 1707px 1444px #767676, 1055px 1217px #767676,
    42px 643px #767676, 953px 628px #767676, 1138px 443px #767676,
    1892px 1119px #767676, 1639px 384px #767676, 1726px 1495px #767676,
    550px 378px #767676, 1490px 483px #767676, 1798px 1762px #767676,
    1164px 1101px #767676, 1043px 448px #767676, 749px 1355px #767676,
    1546px 1260px #767676, 1965px 847px #767676, 1926px 741px #767676,
    663px 368px #767676, 664px 1403px #767676, 488px 542px #767676,
    563px 989px #767676, 371px 332px #767676, 1295px 1776px #767676,
    1173px 1664px #767676, 1251px 845px #767676, 479px 1735px #767676,
    512px 778px #767676, 1393px 715px #767676, 411px 1373px #767676,
    609px 616px #767676, 374px 212px #767676, 1741px 498px #767676,
    1937px 220px #767676, 1129px 1889px #767676, 828px 380px #767676,
    1470px 1681px #767676, 1700px 916px #767676, 721px 1675px #767676,
    1032px 1803px #767676, 1864px 903px #767676, 1394px 1245px #767676,
    339px 457px #767676, 1626px 1573px #767676, 554px 879px #767676,
    1543px 566px #767676, 181px 467px #767676, 1804px 740px #767676,
    256px 1418px #767676, 1421px 420px #767676, 1785px 1552px #767676,
    1712px 1756px #767676, 1484px 1842px #767676, 1458px 1066px #767676,
    1772px 1250px #767676, 1886px 1227px #767676, 807px 1516px #767676,
    1403px 1701px #767676;
  border-radius: 100%;
  animation: starsAnimation 150s linear infinite;
}
.stars > .big:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 40px 1463px #767676, 1666px 1259px #767676, 374px 544px #767676,
    301px 143px #767676, 585px 1229px #767676, 1511px 1364px #767676,
    1036px 350px #767676, 1061px 1815px #767676, 660px 1005px #767676,
    846px 1633px #767676, 350px 749px #767676, 1954px 1678px #767676,
    1012px 446px #767676, 604px 1675px #767676, 1410px 253px #767676,
    385px 1170px #767676, 1943px 1695px #767676, 1513px 1271px #767676,
    1649px 1261px #767676, 810px 177px #767676, 977px 589px #767676,
    882px 94px #767676, 1298px 1113px #767676, 903px 1577px #767676,
    1980px 1354px #767676, 1884px 1534px #767676, 770px 1429px #767676,
    273px 1234px #767676, 56px 150px #767676, 1216px 711px #767676,
    795px 1326px #767676, 521px 1503px #767676, 1995px 633px #767676,
    667px 421px #767676, 533px 675px #767676, 1568px 1815px #767676,
    152px 877px #767676, 1672px 208px #767676, 1256px 1424px #767676,
    145px 791px #767676, 778px 1715px #767676, 49px 544px #767676,
    1627px 188px #767676, 1707px 1444px #767676, 1055px 1217px #767676,
    42px 643px #767676, 953px 628px #767676, 1138px 443px #767676,
    1892px 1119px #767676, 1639px 384px #767676, 1726px 1495px #767676,
    550px 378px #767676, 1490px 483px #767676, 1798px 1762px #767676,
    1164px 1101px #767676, 1043px 448px #767676, 749px 1355px #767676,
    1546px 1260px #767676, 1965px 847px #767676, 1926px 741px #767676,
    663px 368px #767676, 664px 1403px #767676, 488px 542px #767676,
    563px 989px #767676, 371px 332px #767676, 1295px 1776px #767676,
    1173px 1664px #767676, 1251px 845px #767676, 479px 1735px #767676,
    512px 778px #767676, 1393px 715px #767676, 411px 1373px #767676,
    609px 616px #767676, 374px 212px #767676, 1741px 498px #767676,
    1937px 220px #767676, 1129px 1889px #767676, 828px 380px #767676,
    1470px 1681px #767676, 1700px 916px #767676, 721px 1675px #767676,
    1032px 1803px #767676, 1864px 903px #767676, 1394px 1245px #767676,
    339px 457px #767676, 1626px 1573px #767676, 554px 879px #767676,
    1543px 566px #767676, 181px 467px #767676, 1804px 740px #767676,
    256px 1418px #767676, 1421px 420px #767676, 1785px 1552px #767676,
    1712px 1756px #767676, 1484px 1842px #767676, 1458px 1066px #767676,
    1772px 1250px #767676, 1886px 1227px #767676, 807px 1516px #767676,
    1403px 1701px #767676;
  border-radius: 100%;
}

@keyframes starsAnimation {
  from {
    transform: translateY(-2000px);
  }
  to {
    transform: translateY(0px);
  }
}

.hero-heading-wrapper {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}

.hero-heading {
  font-family: "Roboto";
  font-size: 6rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
}

.hero-quote {
  font-family: "Titillium Web";
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.hero-quote div {
  color: #fff;
  text-align: right;
  font-size: 1.5rem;
}

@media screen and (max-width: 1024px) {
  .hero-heading {
    font-size: 3rem;
  }
  .hero-quote {
    max-width: 300px;
  }
  .hero-quote div {
    transform: translateX(-10%);
  }
}

/* POSITIONS */

.positions-wrapper {
  padding: 4rem;
  background-color: #000;
}

.positions-heading {
  font-size: 5rem;
  font-family: "Sofia Sans";
  font-weight: 600;
  display: flex;
  justify-content: space-around;
}

.positions-heading div {
  display: flex;
  align-items: center;
}

.positions-heading div img {
  filter: invert();
}

.positions-main {
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.positions-main-heading {
  font-size: 2.75rem;
  font-family: "Sofia Sans";
  text-align: center;
}

.positions-list-wrapper {
  width: 30vw;
  padding: 4rem;
}

.positions-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.positions-list hr {
  width: 25%;
  height: 1px;
  background-color: #1ebaa3;
  border: none;
  margin: 1rem 0;
}

.positions-list-element {
  list-style: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.positions-list-element div {
  font-family: "Sofia Sans";
  font-size: 1.5rem;
}

.positions-list-element button a {
  text-decoration: none;
}

.positions-list-element button:hover a {
  color: #fff;
}

.positions-list-element button:hover {
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  * {
    overflow-x: hidden;
  }

  .positions-main {
    /* padding: 1rem; */
    width: 80vw;
  }

  .positions-heading {
    font-size: 3rem;
    width: 80vw;
    transform: translateX(-8%);
  }

  .positions-heading img {
    width: 10rem;
  }

  .positions-main {
    transform: translateX(-8%);
  }

  .positions-list-wrapper {
    width: 100vw;
  }

  .positions-list-element div {
    max-width: 50%;
  }

  .positions-list-element button {
    background-color: #1ebaa3;
    color: #fff;
    font-weight: 500;
    transition: 0.15s ease-in-out;
  }

  .positions-list-element button:active,
  .positions-list-element button:focus {
    background-color: #00ffd9;
  }

  .positions-list-element button a {
    color: #fff;
    font-weight: 600;
  }
}

/* FOOTER */

/* .footer-wrapper {
  font-family: "Titillium Web";
  padding: 4rem 16rem;
}

.footer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-logo-img {
  width: 15rem;
}

.footer-logo-text {
  max-width: 300px;
}

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5.5rem;
}

.footer-page-links ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.1rem;
}

.footer-page-links ul li {
  list-style: none;
}

.footer-page-links ul li a {
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 600;
  transition: 0.3s ease-in-out;
}

.footer-page-links ul li a:hover {
  color: #fff;
}

.socialicons {
  display: flex;
  align-items: center;
  gap: 4rem;
}

.socialicons li {
  list-style: none;
}

.socialicons li a i {
  font-size: 1.5rem;
  transition: 0.3s ease-in-out;
}

.socialicons li a i:hover {
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .footer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
} */

@import url("https://fonts.googleapis.com/css2?family=Inconsolata&family=Ubuntu+Mono&family=VT323&display=swap");
* {
  margin: 0;
}

.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.social-icons li {
  display: inline-block;
  margin-bottom: 4px;
}

.social-icons a {
  background-color: #393939;
  color: #bbbbbb;
  font-size: 1.3rem;
  display: inline-block;
  line-height: 44px;
  width: 3rem;
  height: 3rem;
  text-align: center;
  margin: 30px;
  border-radius: 100%;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.social-icons.size-sm a {
  height: 34px;
  width: 34px;
  font-size: 14px;
}

.social-icons li a i {
  color: #fff;
}
.social-icons a.discord:hover,
.social-icons a.twitter:hover,
.social-icons a.linkedin:hover,
.social-icons a.instagram:hover {
  background-color: #1ebaa3;
}

.footer {
  /* margin-top: 6em; */
  background-color: #202020;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.mail-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.footer-img {
  text-align: left;
}
.footer-img img {
  width: 17rem;
  padding: 0;
  margin: 20px;
}
.vertical-line {
  width: 1.5px;
  height: 150px;
  background-color: #393939;
}
.footer-img h2 {
  /* margin-top: -2rem; */
  font-family: "VT323";
  /* margin: 5px; */
  color: #1ebaa3;
  font-size: 1.5rem;
  margin-bottom: 10px;
  position: relative;
  text-align: center;
  /* left: 63px; */
}

.footer-img a {
  text-decoration: none;
}

.footer-img a:hover {
  color: #1ea792;
}
@media screen and (max-width: 700px) {
  .social-icons a {
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
    margin: 12px;
  }
  .footer-img {
    margin-bottom: 0;
  }
  .social-icons {
    margin-bottom: 20px;
  }
  .vertical-line {
    display: none;
  }
  .footer-img h2 {
    text-align: center;
    position: static;
    left: 0;
  }
}
